/*
 * Detecting scroll position
 */
function getCurrentScroll() {
    return window.pageYOffset || document.documentElement.scrollTop;
}



/*
 * ======= ready handler ======
 */
$(document).ready(function(){

    BrowserDetect.init();
    console.log("You are using " + BrowserDetect.browser + " with version " + BrowserDetect.version);

    if ($(".tiny-checkbox__input").length) {
        if (BrowserDetect.browser === 'Explorer') {
            $(".tiny-checkbox__input").addClass("tiny-checkbox__input--ie");
        }
    }

    if (BrowserDetect.browser === 'Safari') {
        $("#panel").addClass("overflow-fix");
    }

    AOS.init({
        duration: 1200
    });

    /*
     * Extendable section
     */
    var $extendableItem = $(".section--extendable");

    $extendableItem.find('.button--extend,.button--collapse').click(function(event) {

        console.log('click');

        event.preventDefault();

        var target = $(this).data("target");

        if (!$(this).closest($extendableItem).hasClass("section--extended")){

            console.log('extend');

            $(target).slideDown(500);
            $(".button--extend[data-target='"+ target +"']").css("display","none");
            $(".button--collapse[data-target='"+ target +"']").css("display","inline-block");
            $(target).closest(".section--extendable").addClass("section--extended");

        } else {

            console.log('close');

            $(target).slideUp(500);
            $(".button--extend[data-target='"+ target +"']").css("display","inline-block");
            $(".button--collapse[data-target='"+ target +"']").css("display","none");
            $(target).closest(".section--extendable").removeClass("section--extended");

        }
        return false;

    });


    /*
     * Collapsible content
     */
    $('.collapse').on('show.bs.collapse', function () {
        if ($(this).parent().find(".button[data-toggle='collapse']").hasClass('button--changeable-label')) {

            var btn = $(this).parent().find(".button[data-toggle='collapse']");

            var $labelText = $(this).parent().find(".button[data-toggle='collapse'] > .button__label").html();
            var $labelParts = $labelText.split('<br>');

            if (btn.data("lang") === 2) {
                var $remainText = $labelParts[1];
                $(this).parent().find(".button[data-toggle='collapse'] > .button__label").html('Close<br>' + $remainText);
            } else {
                var $remainText = $labelParts[0];
                $(this).parent().find(".button[data-toggle='collapse'] > .button__label").html($remainText + '<br>schließen');
            }

        }
    });

    $('.collapse').on('hide.bs.collapse', function () {
        if ($(this).parent().find(".button[data-toggle='collapse']").hasClass('button--changeable-label')) {

            var btn = $(this).parent().find(".button[data-toggle='collapse']");

            var $labelText = $(this).parent().find(".button[data-toggle='collapse'] > .button__label").html();
            var $labelParts = $labelText.split('<br>');

            if (btn.data("lang") === 2) {
                var $remainText = $labelParts[1];
                $(this).parent().find(".button[data-toggle='collapse'] > .button__label").html('View<br>' + $remainText);
            } else {
                var $remainText = $labelParts[0];
                $(this).parent().find(".button[data-toggle='collapse'] > .button__label").html($remainText + '<br>ansehen');
            }
        }
    });


    $('a[href$="#online-bewerbung"]').click(function() {


        $("html, body").animate({
            scrollTop:$('#online-bewerbung').offset().top
        },'fast');

        $(".button--extend[data-target='#application']").click();

    });


    /*
     * ReferenceItem visibility
     */
    $('#close-reference').click(function() {
        $("#panel").css('display','block');
        $(".reference").fadeOut();
        $("html, body").animate({
            scrollTop:$('#reference-filter').offset().top-100
        },'fast');

        $("body").removeClass("no-padding");

        return false;
    });

    $('.fancybox').magnificPopup({type:'image'});

    /*
     * Tooltip
     */
    $('.tooltip-trigger').tooltip();


    /*
     * Select state appearance
     */
    $(".form__item--type-select").on('change', function() {
        if ($(this).val() !== 'default'){
            $(this).addClass('form__item--active-select');
            $(this).parent().find(".button--select").addClass('button--black button--checkmark');
            $(this).parent().find(".button--select").removeClass('button--yellow button--chevron-down');
        } else {
            $(this).removeClass('form__item--active-select');
            $(this).parent().find(".button--select").removeClass('button--black button--checkmark');
            $(this).parent().find(".button--select").addClass('button--yellow button--chevron-down');
        }
    });


    /*
     * ScrollNav
     */
    // var offset=50;
    //
    // $('#main-nav').find('.level2 a[href*="#"]').click(function (event) {
    //     //event.preventDefault();
    //     console.log(this.hash);
    //
    //     var hash = this.hash;
    //     var target = $(hash);
    //
    //     //if (target.length === 0) target = $('a[name="' + hash.substr(1) + '"]');
    //     if (target.length === 0) {
    //         target = $('html');
    //     }
    //
    //     $('html, body').animate({
    //         scrollTop: target.offset().top-offset
    //     }, 500, function () {
    //         if(history.pushState) {
    //             history.pushState(null, null, '/' + hash);
    //         } else {
    //             location.hash = hash;
    //         }
    //     });
    //     return false;
    // });

    $(function () {
        $('#main-nav').find('.level2 a[href*="#"]').bind('click', function (event) {
            console.log('click anchor');
            var $anchor = $(this);
            $('html, body').stop().animate({
                scrollTop: $($anchor.attr('href').replace(/\//g, '')).offset().top-80
            }, 500);
            event.preventDefault();
        });
    });


    /*
     * Header transformation
     */
    var shrinkHeader = 115;
    $(window).scroll(function() {

        var scroll = getCurrentScroll();

        if ( scroll >= shrinkHeader ) {
            $('.header').addClass('sticky');
            $('body').addClass('sticky-padding');
        }
        else {
            $('.header').removeClass('sticky');
            $('body').removeClass('sticky-padding');
        }
    });


    /*
     * OptionBar slide in
     */
    $(".optionbar__item button").click(function(){

        var target = $(this).data("target");

        if (!$(target).hasClass("optionbar__flyout--visible")){
            $(target).addClass("optionbar__flyout--visible");
            if (target === ".search-form") {
                $(".search-form").find(".search-form__input").focus();
            }
        } else {
            $(target).removeClass("optionbar__flyout--visible");
        }
    });


    /*
     * Scroll top button
     */
    $('.scrolltop').click(function() {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        return false;
    });


    /*
     * OptionBar visibility
     */
    $(window).bind("scroll", function() {
        if ($(this).scrollTop() > 50) {
            $(".scrolltop, .optionbar").fadeIn();
        } else {
            $(".scrolltop, .optionbar").stop().fadeOut();
        }
    });


    $('.gallery-modal').on('show.bs.modal', function (e) {
        $(this).appendTo("body");
    });


    /*
    * Initialize Gallery slider inside Bootstrap-Modal
    */
    $('.gallery-modal').on('shown.bs.modal', function (e) {
        var $slider = $(this).find('.gallery-slider');
        var $nav    = $(this).find('.gallery-slider-nav');

        $slider.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            fade: true,
            asNavFor: $nav,
            nextArrow: '<span class="slickarrow slickarrow--next button button--arrow-r button--black"></span>',
            prevArrow: '<span class="slickarrow slickarrow--previous button button--arrow-l button--black"></span>'
            // adaptiveHeight: true
        });

        $nav.slick({
            slidesToShow: 6,
            slidesToScroll: 1,
            arrows: false,
            asNavFor: $slider,
            dots: false,
            centerMode: false,
            focusOnSelect: true,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 4
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 4,
                        swipe: true
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3,
                        swipe: true
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 3,
                        swipe: true
                    }
                }
            ]

        });
    });


    /*
     * Box slider - Einrichtungslösungen
     */
    $('.box-slider--solutions').slick({
        dots: true,
        arrows: false,
        autoplay: true,
        slidesToShow: 5,
        infinite: true,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 5,
                    //swipe: true,
                    dots: true,
                    arrows: false
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    //swipe: true,
                    dots: true,
                    arrows: false
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    //swipe: true,
                    dots: true,
                    arrows: false
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 3,
                    //swipe: true,
                    dots: true,
                    arrows: false,
                    centerMode: true,
                    centerPadding: '15px'
                }
            }
        ]
    });

    /*
     * Box slider - Referenzen
     */
    $refSlider = $('.box-slider--references').slick({
        dots: true,
        arrows: false,
        autoplay: true,
        slidesToShow: 5,
        infinite: true,
        slidesToScroll: 1,
        //centerMode: true,
        centerPadding: '40px',
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    //swipe: true,
                    dots: true,
                    arrows: false
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    //swipe: true,
                    dots: true,
                    arrows: false
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    //swipe: true,
                    dots: true,
                    arrows: false,
                    centerPadding: '15px'
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 3,
                    //swipe: true,
                    dots: true,
                    arrows: false,
                    centerPadding: '15px'
                }
            }
        ]
    });

    $('.ref-next').click(function() {
        $refSlider.slick('slickNext');
    });

    $('.ref-prev').click(function() {
        $refSlider.slick('slickPrev');
    });


    /*
     * Big slider
     */
    $('.big-slider').slick({
        dots: true,
        arrows: true,
        autoplay: true,
        infinite: true,
        //swipe: false,
        // customPaging: function(slider, i) {
        //     var title = $(slider.$slides[i]).data('title');
        //     return '<a class="slider-main__nav-btn" href="javascript:void(0);">'+title+'</a>';
        // },
        nextArrow: '<span class="slickarrow slickarrow--next button button--arrow-r button--black"></span>',
        prevArrow: '<span class="slickarrow slickarrow--previous button button--arrow-l button--black"></span>',
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    //swipe: true,
                    //dots: true,
                    arrows: false
                }
            },
            {
                breakpoint: 992,
                settings: {
                    //swipe: true,
                    //dots: true,
                    arrows: false
                }
            },
            {
                breakpoint: 768,
                settings: {
                    //swipe: true,
                    //dots: true,
                    arrows: false
                }
            },
            {
                breakpoint: 576,
                settings: {
                    //swipe: true,
                    //dots: true,
                    arrows: false
                }
            }
        ]
    });


    /*
     * Right mobile nav
     */
    var rightMenu = document.getElementById('menu-right');

    document.querySelector('.navbar-toggle--right').addEventListener('click', function() {
        slideoutRight.toggle();

        rightMenu.style.zIndex = '0';
        $(this).addClass('active');

        slideoutRight.on('close', function () {
            rightMenu.style.zIndex = '-1';
            $('.navbar-toggle--right').removeClass('active');

        });
    });

    var slideoutRight = new Slideout({
        'panel': document.getElementById('panel'),
        'menu': document.getElementById('menu-right'),
        'padding': 256,
        'tolerance': 70,
        'side': 'right',
        'touch': false
    });

    //on slideout without toggle button
    slideoutRight.on('beforeopen', function () {
        rightMenu.style.zIndex = '0';
    });

    //touch option onlye enabled für closing (touch opening conflicts with slick-slider)
    slideoutRight.once('open', slideoutRight._initTouchEvents);
    slideoutRight.on('open', slideoutRight.enableTouch);
    slideoutRight.on('close', slideoutRight.disableTouch);


    /*
     * Left mobile nav
     */
    var leftMenu = document.getElementById('menu-left');

    document.querySelector('.navbar-toggle--left').addEventListener('click', function() {
        slideoutLeft.toggle();

        leftMenu.style.zIndex = '0';
        $(this).addClass('active');

        slideoutLeft.on('close', function () {
            leftMenu.style.zIndex = '-1';
            $('.navbar-toggle--left').removeClass('active');
        });
    });

    var slideoutLeft = new Slideout({
        'panel': document.getElementById('panel'),
        'menu': document.getElementById('menu-left'),
        'padding': 256,
        'tolerance': 70,
        'side': 'left',
        'touch': false
    });

    //on slideout without toggle button
    slideoutLeft.on('beforeopen', function () {
        leftMenu.style.zIndex = '0';
    });

    //touch option onlye enabled für closing (touch opening conflicts with slick-slider)
    slideoutLeft.once('open', slideoutLeft._initTouchEvents);
    slideoutLeft.on('open', slideoutLeft.enableTouch);
    slideoutLeft.on('close', slideoutLeft.disableTouch);


    /*
     * Mobile nav targeting
     */
    $('.mobile-nav').find('.dropdown > a').on('click', function(event) {

        event.preventDefault();

        if($(this).parent().hasClass('open')) {
            location.assign($(this).attr('href'));
        } else {
            $('.mobile-nav').find('.dropdown').removeClass('open');
            $(this).parent().addClass('open');
        }

    });


});